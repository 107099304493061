




















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import type { ConfigurableVariant } from '~/composables';
import CategoryProductPrice from '~/modules/catalog/category/components/views/CategoryProductPrice.vue';
import {
  ProductWithCommonProductCardProps
} from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';

export default defineComponent({
  name: 'GridDefault',
  components: {
    CategoryProductPrice,
  },
  props: {
    variant: {
      type: [Object, null] as PropType<ConfigurableVariant | null>,
      default: null,
    },
    product: {
      type: Object as PropType<ProductWithCommonProductCardProps>,
      required: true,
    },
  },
});
