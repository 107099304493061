


































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ConfigurableVariant } from '~/composables';
import {
  ProductWithCommonProductCardProps
} from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import {
  getAttributeValue,
  BBRProductAttributes,
  hasMoreVariants
} from '~/bbrTheme/modules/catalog/getters/productGetters';
import GridDefault from '~/bbrTheme/modules/catalog/category/components/views/grid/family/Default.vue';

export default defineComponent ({
  name: 'GridSpirits',
  mixins: [GridDefault],
  props: {
    variant: {
      type: [Object, null] as PropType<ConfigurableVariant | null>,
      default: null,
    },
    product: {
      type: Object as PropType<ProductWithCommonProductCardProps>,
      required: true,
    },
  },
  setup(props) {
    const preparedProduct = props.product || props.variant?.product;

    const productStyle = getAttributeValue(preparedProduct, BBRProductAttributes.STYLE_TEMP);

    return {
      preparedProduct,
      productStyle,
      hasMoreVariants,
    };
  }
});
